import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import * as moment from 'moment-timezone';
import {Shared} from "../../../../shared/shared";

@Component({
  selector: 'calendar-component' ,
  templateUrl: 'calendarComponent.html',
  styleUrls: ['calendarComponent.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Output() changeCalendar:  EventEmitter<{row: any, date: string}> = new EventEmitter();
  rowData: any;
  showDate = false;

  defaultDate;

  constructor(private shared: Shared) {}

  ngOnInit() {
    if(this.value == null) {
      this.defaultDate = moment.tz().toDate();
    }else{
      this.defaultDate = moment.tz(this.value).toDate();
    }
  }

  change(event){
    this.value = moment(event.value).format('yyyy-MM-DDTHH:mm:ss');
    this.value = moment.tz(this.value).tz(this.shared.SERVER_TIMEZONE).format('yyyy-MM-DDTHH:mm:ss');
    this.changeCalendar.emit({row: this.rowData, date: this.value});
  }
}
