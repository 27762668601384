import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Magnament } from './magnament';

const routes: Routes = [
  {
    path: '',
    component: Magnament,
    children: [
      /*{
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },*/{
        path: 'exams',
        loadChildren: () => import('./exams/exams.module').then(m => m.ExamsModule)
      },{
        path: 'subjects',
        loadChildren: () => import('./subjects/subjects.module').then(m => m.SubjectsModule)
      },{
        path: 'translations',
        loadChildren: () => import('./translation/translation.module').then(m => m.TranslationModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class MagnamentRoutingModule {}
