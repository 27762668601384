import {Component, SecurityContext, ViewChild} from '@angular/core';
import {Shared} from "../../shared/shared";
import {ExamService} from "../../../service/ExamService";
import {ExamEntity} from "../../../model/ExamEntity";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {EstimationSettingsEntity} from "../../../model/EstimationSettingEntity";
import {ChartDataSets, ChartOptions} from "chart.js";
import {Label} from "ng2-charts";
import {OfficialPlaceEntity} from "../../../model/OfficialPlaceEntity";
import {PlacesService} from "../../../service/PlacesService";
import {PlacesWithPaginationDto} from "../../../model/PlacesWithPaginationDto";

@Component({
  selector: 'app-exams',
  templateUrl: 'exams.html',
  styleUrls: ['exams.scss'],
  providers: []
})
export class Exams {

  exams: ExamEntity[];
  current: ExamEntity;
  estimationSettings : EstimationSettingsEntity[];

  cutScore = 0;
  mu = 0;
  sigma = 0;
  population = 0;
  handicap = 0;
  testExponential = 0;

  tailChartStart = 0;
  tailChartMiddle = 0;
  tailChartPositions = 0;

  mean = 0;
  median = 0;
  totalPopulationGenerated = 0;

  especilities: (number | string)[];
  provinces: (number | string)[];
  towns: (number | string)[];
  hospitals: (number | string)[];

  especialityFiltered: string = null;
  provinceFiltered: string = null;
  townFiltered: string = null;
  hospitalFiltered: string = null;

  places: OfficialPlaceEntity[];
  placesFiltered: OfficialPlaceEntity[] = [];
  currentPage = 1;
  totalPages = 1;
  testPosition = 100;

  isRecorrecting = false;
  isReestimating = false;
  isrecorrected = null;
  isreestimated = null;

  yearsSelected: String[] = [];

  mirYears = [2018,2019,2020,2021,2022,2023,2024];
  eirYears = [2022, 2023, 2024]

  testExp = 5;
  callYear1 = 0;
  callYear2 = 0;
  positions =[
    {'score': 10, 'position': -1},{'score': 20, 'position': -1},{'score': 30, 'position': -1},{'score': 40, 'position': -1},{'score': 50, 'position': -1},{'score': 60, 'position': -1},{'score': 70, 'position': -1},{'score': 80, 'position': -1},{'score': 90, 'position': -1},{'score': 100, 'position': -1},{'score': 110, 'position': -1},{'score': 120, 'position': -1},{'score': 130, 'position': -1},{'score': 140, 'position': -1},{'score': 150, 'position': -1},{'score': 160, 'position': -1},{'score': 170, 'position': -1},{'score': 180, 'position': -1},{'score': 190, 'position': -1},{'score': 200, 'position': -1}
  ]

  positionsByYear1 =[
    {'score': 10, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 20, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 30, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 40, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 50, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 60, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 70, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 80, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 90, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 100, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 110, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 120, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 130, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 140, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 150, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 160, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 170, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 180, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 190, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 200, 'positionCallYear': -1, 'positionThisYear': -1},
  ];

  positionsByYear2 =[
    {'score': 10, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 20, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 30, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 40, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 50, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 60, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 70, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 80, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 90, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 100, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 110, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 120, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 130, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 140, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 150, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 160, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 170, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 180, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 190, 'positionCallYear': -1, 'positionThisYear': -1},
    {'score': 200, 'positionCallYear': -1, 'positionThisYear': -1},
  ];

  lineChartData: ChartDataSets[] = [];
  lineChartOptions: (ChartOptions & { annotation: any }) = {annotation: undefined, responsive: true};
  lineChartLabels: Label[] = ['200', '196', '192', '188', '184', '180', '176', '172', '168', '164',
    '160', '156', '152', '148', '144', '140', '136', '132', '128', '124',
    '120', '116', '112', '108', '104', '100', '96',  '92',  '88',  '84',
    '80',  '76',  '72',  '68',  '64',  '60',  '56',  '52',  '48',  '44',
    '40',  '36',  '32',  '28',  '24',  '20',  '16',  '12',  '8',   '4'];

  headers: any = {
    columns: {
      id: {title: 'ID', hide: true, filter: false},
      reference: {title: 'Reference', hide: true, filter: false},
      title: {title: 'Title', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let exam = this.exams.find(x => x.id == data.row.id)
              exam.title = data.text;
              this.saveExam(exam);
            });
          }
        },
      },
      category: {title: 'Category', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let exam = this.exams.find(x => x.id == data.row.id)
              exam.category = data.text;
              this.saveExam(exam);
            });
          }
        },
      },
      callYear: {title: 'Call year', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let exam = this.exams.find(x => x.id == data.row.id)
              exam.callYear = data.text;
              this.saveExam(exam);
            });
          }
        },
      },
      numQuestions: {title: 'Num questions', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let exam = this.exams.find(x => x.id == data.row.id)
              exam.numQuestions = data.text;
              this.saveExam(exam);
            });
          }
        },
      },
      reservedQuestions: {title: 'Reserved questions', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let exam = this.exams.find(x => x.id == data.row.id)
              exam.reservedQuestions = data.text;
              this.saveExam(exam);
            });
          }
        },
      },
      successValue: {title: 'Success value', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let exam = this.exams.find(x => x.id == data.row.id)
              exam.successValue = data.text;
              this.saveExam(exam);
            });
          }
        },
      },
      wrongValue: {title: 'Wrong value', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let exam = this.exams.find(x => x.id == data.row.id)
              exam.wrongValue = data.text;
              this.saveExam(exam);
            });
          }
        },
      },
      url: {title: 'URL', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let exam = this.exams.find(x => x.id == data.row.id)
              exam.url = data.text;
              this.saveExam(exam);
            });
          }
        },
      },
    },
    actions: false,
  }


  headers2: any = {
    columns: {
      id: {title: 'ID', hide: true, filter: false},
      examId: {title: 'Reference', hide: true, filter: false},
      parameter: {title: 'Parameter', hide: false, filter: true},
      valueDouble: {title: 'Double', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let estimation = this.estimationSettings.find(x => x.id == data.row.id)
              estimation.valueDouble = data.text;
              this.saveEstimationSetting(estimation);
            });
          }
        },
      },
      valueInt: {title: 'Integer', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let estimation = this.estimationSettings.find(x => x.id == data.row.id)
              estimation.valueInt = data.text;
              this.saveEstimationSetting(estimation);
            });
          }
        },
      },
      valueString: {title: 'String', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let estimation = this.estimationSettings.find(x => x.id == data.row.id)
              estimation.valueString = data.text;
              this.saveEstimationSetting(estimation);
            });
          }
        },
      },
    },
    actions: false,
  }

  constructor(private shared: Shared, private examService: ExamService, private placesService: PlacesService) {
  }

  ngOnInit() {
    this.examService.getExams().subscribe(x => {
      if (x != null) {
        this.exams = x;
      }
    });
  }

  rowSelectedEmitter(row: ExamEntity) {
    this.current = row;

    this.placesService.getPlaces(this.current.callYear, this.current.corpId).subscribe((p: OfficialPlaceEntity[]) => {
      if (p != null) {
        this.places = p;

        this.updateDropdown();
      }
    });

    this.examService.getEstimationSettings(this.current.id).subscribe(x => {
      if (x != null) {
        this.estimationSettings = x;

        this.estimationSettings.forEach(t=>{
          if(t.parameter === 'NOTA_DE_CORTE'){
            this.cutScore = t.valueDouble;
          }else if(t.parameter === 'MU'){
            this.mu = t.valueDouble;
          }else if(t.parameter === 'SIGMA'){
            this.sigma = t.valueDouble;
          }else if(t.parameter === 'POPULATION'){
            this.population = t.valueInt;
          }else if(t.parameter === 'EXPONENTIAL_PLACE'){
            this.testExponential = t.valueInt;
          }else if(t.parameter === 'HANDICAP_POSITION'){
            this.handicap = t.valueInt;
          }else if(t.parameter === 'TAIL_CHART_START'){
            this.tailChartStart = t.valueDouble;
          }else if(t.parameter === 'TAIL_CHART_MIDDLE'){
            this.tailChartMiddle = t.valueDouble;
          }else if(t.parameter === 'TAIL_CHART_POSITIONS'){
            this.tailChartPositions = t.valueDouble;
          }
        });
      }
    });
  }

  filterPlaces(bool) {
    if (bool) {
      this.placesFiltered = [];
      this.currentPage = 1;
    }

    if(this.placesFiltered.length>0 || bool){
      if(this.currentPage == 1 || this.currentPage <= this.totalPages) {
        let filterObject = {
          'especiality': this.especialityFiltered,
          'province': this.provinceFiltered,
          'town': this.townFiltered,
          'hospital': this.hospitalFiltered,
          'nextPage': this.currentPage
        };
        this.placesService.filterPlacesByPage(filterObject, this.testPosition, this.testExponential, this.current.corpId).subscribe((p: PlacesWithPaginationDto) => {
          if (p != null) {
            this.placesFiltered = this.placesFiltered.concat(p.places);
            this.currentPage = p.currentPage+1;
            this.totalPages = p.totalPages;
            this.placesFiltered = this.placesFiltered.filter(x => x.percentage != -1).sort((a, b) => b.percentage - a.percentage);
          }
        });
      }
    }
  }

  recalculateExams(){
    this.isRecorrecting = true;
    this.isrecorrected = null;
    this.examService.reCorrectExams(this.current.id).subscribe(t => {
      if (t != null) {
        this.isRecorrecting = false;
        this.isrecorrected = t;
      }
    });
  }

  reestimateExams(){
    this.isReestimating = true;
    this.isreestimated = null;
    this.examService.reEstimateExams(this.current.id).subscribe(t => {
      if (t != null) {
        this.isReestimating = false;
        this.isreestimated = t;
      }
    });
  }

  async saveExam(exam: ExamEntity) {
    this.examService.saveExam(exam).subscribe(t => {
      if (t != null) {
      }
    });
  }

  async saveEstimationSetting(estimation: EstimationSettingsEntity) {
    this.examService.saveEstimation(estimation).subscribe(t => {
      if (t != null) {
      }
    });
  }

  recalculatePositions() {
    this.positions.forEach(x=>{
      this.examService.getPosition(this.current.id, x.score, this.testExp, this.mu, this.sigma, this.population, this.handicap, this.tailChartStart, this.tailChartMiddle, this.tailChartPositions).subscribe(t => {
        if (t != null) {
          x.position = t;
        }
      });
    });
  }

  recalculatePositionsByYear() {
    this.positionsByYear1.forEach(x=>{
      this.examService.getPositionByYear(this.current.corpId,this.current.id, x.score, this.callYear1, this.mu, this.sigma, this.population, this.handicap, this.tailChartStart, this.tailChartMiddle, this.tailChartPositions).subscribe(t => {
        if (t != null) {
          x.score = t[0];
          x.positionCallYear = t[1];
          x.positionThisYear = t[2];
        }
      });
    });
    if(this.callYear2 != 0 && this.callYear2 != null){
      this.positionsByYear2.forEach(x=>{
        this.examService.getPositionByYear(this.current.corpId,this.current.id, x.score, this.callYear2, this.mu, this.sigma, this.population, this.handicap, this.tailChartStart, this.tailChartMiddle, this.tailChartPositions).subscribe(t => {
          if (t != null) {
            x.score = t[0];
            x.positionCallYear = t[1];
            x.positionThisYear = t[2];
          }
        });
      });
    }
  }

  recalculate(){
    let distribution = this.generateLogNormalDistribution(this.population,0,100,50, this.mu, this.sigma);

    this.calculateLogNormalStats(distribution);

    let dis = this.fixTailChart();
    for (let i = 0; i < distribution.length; i++) {
      if(this.tailChartPositions < 0) {
        distribution[i] = distribution[i] + (dis[i]*-1);
      }else{
        distribution[i] = distribution[i] + dis[i];
      }
    }

    this.lineChartData.push({
      data: distribution,
      label: 'Serie',
      fill: false,
      backgroundColor: this.randomColor() })

    //this.calculateLogNormalStats(distribution);
  }

  fixTailChart() {
    const final = 50;
    let pos = 0;

    if(this.tailChartPositions<0){
      pos = Math.abs(this.tailChartPositions);
    }else{
      pos = this.tailChartPositions;
    }

    const p = (this.tailChartMiddle - this.tailChartStart) / (final - this.tailChartStart);
    console.log(p)

    const p1 = Math.floor(p * pos);
    const p2 = pos - p1;

    console.log(`Posiciones de 23 a 37: ${p1}`);
    console.log(`Posiciones de 37 a 50: ${p2}`);

    const distribucion = new Array(final).fill(0);

    let sumAscendente = 0;
    for (let i = this.tailChartStart; i <= this.tailChartMiddle; i++) {
      sumAscendente++;
      distribucion[i] = Math.floor((sumAscendente / (this.tailChartMiddle - this.tailChartStart)) * p1);
    }

    let sumDescendente = 50-this.tailChartMiddle;
    for (let i = this.tailChartMiddle + 1; i <= final - 1; i++) {
      distribucion[i] = Math.floor((sumDescendente / (final - this.tailChartMiddle)) * p2);
      sumDescendente--;
    }

    console.log(distribucion);

    const sumaDistribucion = distribucion.reduce((acc, curr) => acc + curr, 0);
    if (sumaDistribucion !== pos) {
      distribucion[final] += pos - sumaDistribucion;
    }

    return distribucion;
  }

  checkYear(year){
    return this.yearsSelected.includes(year);
  }

  addNewYear(year){
    if(this.checkYear(year)){
      this.yearsSelected = this.yearsSelected.filter(y => y !== year);
      this.lineChartData = this.lineChartData.filter(f=> f.label !== year);
    }else {
      this.yearsSelected.push(year);

      let distribution = [];

      this.examService.getOtherYears(year, this.current.category).subscribe(t => {
        if (t != null) {
          let b = Array.from({ length: 50 }, (_, i) => 0 + i * 2);
          distribution = b.map(bin => {
              return t.filter(nota => nota >= bin && nota < (bin + 2)).length
            }
          );
          let c = this.randomColor();
          this.lineChartData.push({
            data: distribution.reverse(),
            label: year,
            fill: false,
            backgroundColor: c,
            borderColor: c,
            borderWidth: 2
          })
        }
      });
    }
  }

  randomColor(): string {
    const r = Math.floor(Math.random() * 256); // Rango de 0-255
    const g = Math.floor(Math.random() * 256); // Rango de 0-255
    const b = Math.floor(Math.random() * 256); // Rango de 0-255
    return `rgb(${r},${g},${b})`; // Retorna el color en formato RGB
  }

  async updateDropdown() {
    await this.delay(200);
    this.especilities = this.getUniqueValues('especiality');
    this.provinces = this.getUniqueValues('province');
    this.towns = this.getUniqueValues('town');
    this.hospitals = this.getUniqueValues('hospital');
  }

  getUniqueValues(variable: keyof OfficialPlaceEntity) {
    const filteredPlaces = this.places.filter(objeto =>
      (this.especialityFiltered === null || objeto.especiality === this.especialityFiltered) &&
      (this.provinceFiltered === null || objeto.province === this.provinceFiltered) &&
      (this.townFiltered === null || objeto.town === this.townFiltered) &&
      (this.hospitalFiltered === null || objeto.hospital === this.hospitalFiltered)
    );

    // Ahora obtén los valores únicos y ordénalos
    return Array.from(new Set(filteredPlaces.map(objeto => objeto[variable]))).sort();
  }

  generateLogNormalDistribution(populationSize, min, max, bins, mu, sigma) {
    // Función de densidad de probabilidad (PDF) de la distribución log-normal
    function logNormalPdf(x, mu, sigma) {
      if (x === 0) return 0;
      return (1 / (x * sigma * Math.sqrt(2 * Math.PI))) * Math.exp(-Math.pow(Math.log(x) - mu, 2) / (2 * sigma * sigma));
    }

    let binSize = (max - min) / bins;
    let distribution = new Array(bins).fill(0);
    let totalProb = 0;

    // Calcular la probabilidad total usando una aproximación de la integral
    for (let x = min; x < max; x += 0.01) {
      totalProb += logNormalPdf(x, mu, sigma) * 0.01;
    }

    // Asignar la población a cada bin según las probabilidades
    for (let i = 0; i < bins; i++) {
      let binProb = 0;
      for (let x = min + i * binSize; x < min + (i + 1) * binSize; x += 0.01) {
        binProb += logNormalPdf(x, mu, sigma) * 0.01;
      }
      distribution[i] = Math.round((binProb / totalProb) * populationSize);
    }
    // Ajuste final para asegurar que la suma total sea 12000
    let totalPopulation = distribution.reduce((a, b) => a + b, 0);
    while (totalPopulation > populationSize) {
      distribution[distribution.findIndex(x => x < Math.max(...distribution))] += (populationSize - totalPopulation);
      totalPopulation = distribution.reduce((a, b) => a + b, 0);
    }
    return distribution;
  }

  calculateLogNormalStats(distribution) {
    const intervalWidth = 4; // Cada intervalo representa 4 puntos
    let totalUsers = 0;
    let totalPoints = 0;

    // Calcular el total de usuarios y puntos
    distribution.forEach((count, index) => {
      const midpoint = (index * intervalWidth) + intervalWidth / 2; // Punto medio del intervalo
      totalUsers += count;
      totalPoints += midpoint * count;
    });

    // Calcular la media
    this.mean = 200 - (totalPoints / totalUsers);

    // Calcular la mediana
    let cumulativeUsers = 0;
    const medianTarget = totalUsers / 2;
    let median = 0;

    for (let i = 0; i < distribution.length; i++) {
      cumulativeUsers += distribution[i];
      if (cumulativeUsers >= medianTarget) {
        // Encontramos el intervalo donde cae la mediana
        this.median = 200 - ((i * intervalWidth) + intervalWidth / 2);
        break;
      }
    }


    /*mu = parseFloat(Number(mu).toFixed(2));
    sigma = parseFloat(Number(sigma).toFixed(2));

    this.mean = 200 - Math.exp(mu + (sigma ** 2) / 2) * 2;
    this.median = 200 - Math.exp(mu) * 2;*/

    /*if (category == 'MIR') {
      this.mean = Math.exp(mu + (sigma ** 2) / 2) * 2;
      this.median = Math.exp(mu) * 2;
    }
    else if (category == 'EIR') {
      this.mean = Math.exp(mu + (sigma ** 2) / 2) * 0.89
      this.median = Math.exp(mu) * 0.89
    }*/
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}


