import {Component} from '@angular/core';
import {Shared} from "../shared/shared";

@Component({
  selector: 'app-toolbar',
  templateUrl: 'toolbar.html',
  styleUrls: ['toolbar.scss']
})
export class Toolbar {

  constructor(private shared: Shared) {}

}
