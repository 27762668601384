import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {TranslationEntity} from "../model/TranslationEntity";

@Injectable()
export class TranslationService extends ParentService{

  public getTranslations(){
    return this.http.get<TranslationEntity[]>(this.shared.MAGNAMENT_URL+'/getTranslations');
  }

  public saveTranslation(translation: TranslationEntity){
    return this.http.post<TranslationEntity>(this.shared.MAGNAMENT_URL+'/saveTranslation', translation)
  }

}
