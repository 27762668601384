import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {SubjectEntity} from "../../../model/SubjectEntity";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {SubjectService} from "../../../service/SubjectService";

@Component({
  selector: 'app-subject',
  templateUrl: 'subjects.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class Subjects {

  @Input() subjects: SubjectEntity[];

  @Output() filter = new EventEmitter<number>();

  newEntity: SubjectEntity;
  modeAdd = false;

  headers: any = {
    columns: {
      id: {title: 'id', hide: true, filter: false},
      alias: {title: 'Alias', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let subject = this.subjects.find(x => x.id == data.row.id)
              subject.alias = data.text;
              this.saveSubject(subject, false);
            });
          }
        },
      },
      name: {title: 'Name', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let subject = this.subjects.find(x => x.id == data.row.id)
              subject.name = data.text;
              this.saveSubject(subject, false);
            });
          }
        },
      },
      icon: {title: 'Icon', hide: false, filter: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let subject = this.subjects.find(x => x.id == data.row.id)
              subject.icon = data.text;
              this.saveSubject(subject, false);
            });
          }
        },
      }
    },
    actions: false,
  }

  constructor(private shared: Shared, private subjectService: SubjectService) {
  }

  ngOnInit() {
    console.log(this.subjects);
  }

  saveSubject(subject: SubjectEntity,bool) {
    this.subjectService.saveSubject(subject).subscribe( t =>{
      if(t!=null) {
        if(bool){this.subjects.push(t);}
        this.subjects = this.subjects.filter(x => {return x});
      }
    });
  }

  async saveNewSubject() {
    this.saveSubject(this.newEntity,true);
    this.modeAdd = false;
  }

  newSubject(){
    this.modeAdd = true;
    this.newEntity = new SubjectEntity();
  }

}
