import {Component, ViewEncapsulation} from '@angular/core';
import {Shared} from "../shared/shared";
import {LoginService} from "../../service/LoginService";

@Component({
  selector: 'app-magnament',
  templateUrl: 'magnament.html',
  styleUrls: ['magnament.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Magnament {

  isMenuColapsed: Boolean = true;

  constructor(private loginService: LoginService ,private shared: Shared) {
    this.shared.CURRENT_MANAGER = JSON.parse(localStorage.getItem('CURRENT_MANAGER'));
  }

  ngOnInit() {
  }

  closeMenu(){
    this.isMenuColapsed = true;
  }
}
