import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Exams } from './exams';
import {ExamsRoutingModule} from "./exams-routing.module";
import {TableModule} from "../generic/table/table.module";
import {ExamService} from "../../../service/ExamService";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {NgToggleModule} from "ng-toggle-button";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {SwiperModule} from "swiper/angular";
import { ChartsModule } from 'ng2-charts';
import {PlacesService} from "../../../service/PlacesService";

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ExamsRoutingModule,
        TableModule,
        DlDateTimePickerModule,
        NgToggleModule,
        NgSelectModule,
        NgxExtendedPdfViewerModule,
        SwiperModule,
        ChartsModule
    ],
  providers: [ExamService, PlacesService],
  exports: [
    Exams
  ],
    declarations: [Exams]
})
export class ExamsModule {}
