import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {ExamEntity} from "../model/ExamEntity";
import {Shared} from "../view/shared/shared";
import {EstimationSettingsEntity} from "../model/EstimationSettingEntity";

@Injectable()
export class PlacesService extends ParentService{

  public getPlaces(year) {
    return this.http.get(this.shared.MAGNAMENT_URL+'/getPlaces/'+year, this.shared.headers());
  }
  public filterPlacesByPage(filterObject, position, exponential) {
    return this.http.post(this.shared.MAGNAMENT_URL+'/filterPlacesByPage/'+position+'/'+exponential, filterObject, this.shared.headers());
  }
}
