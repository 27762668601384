import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {Shared} from '../view/shared/shared';


@Injectable()
export class ParentService {

  constructor(public http:HttpClient, public shared: Shared){
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
  }

  showError(error){
    console.log("Error detected");
    console.log(error);
  }



}
