import {Injectable, Injector} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Location} from '@angular/common';
import { Device } from '@ionic-native/device/ngx';
import {Platform} from "@ionic/angular";
import * as moment from "moment";
import {TranslationEntity} from "../../model/TranslationEntity";
import {ManagerEntity} from "../../model/ManagerEntity";

@Injectable()
export class Shared {

  //MAGNAMENT_URL = "http://localhost:8102";
  //MAGNAMENT_URL = "http://192.168.0.229:8102";
  //MAGNAMENT_URL = "http://192.168.41.14:8102";
  MAGNAMENT_URL = "https://controlhub-back.mediplus.es"
  CHAT_URL = "https://chat-back.mediplus.es"
  //CHAT_URL = "http://192.168.40.206:8103";

  public CURRENT_MANAGER: ManagerEntity;
  public CURRENT_SUBS_NUMBER: number = 0;
  public TRANSLATIONS: TranslationEntity[];
  public CURRENT_LANGUAGE = 'ES';
  public CURRENT_IP: string;
  public CURRENT_LOCATION: any;
  public SERVER_TIMEZONE = 'Etc/GMT';
  public DARKMODE: boolean = false;

  public isMenuColapsed = true;

  constructor(private router: Router, private route: ActivatedRoute, private location: Location, private http: HttpClient, private device: Device, private platform: Platform) {

    if(this.checkHostInReferrer('localhost')) {
      console.log("Local");
      this.MAGNAMENT_URL = "http://192.168.1.20:8102";
      //this.MAGNAMENT_URL = "http://192.168.0.139:8102";
    }

    this.CURRENT_MANAGER = JSON.parse(localStorage.getItem('CURRENT_MANAGER'));

    if (this.CURRENT_MANAGER == null || this.CURRENT_MANAGER == undefined || this.CURRENT_MANAGER == null) {
      this.goTo(['/', 'login']);
    } else {
      this.afterLogin(this.CURRENT_MANAGER.id);
    }
  }

  afterLogin(managerId) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get<ManagerEntity>(this.MAGNAMENT_URL + '/getContext/' + managerId).subscribe((x: ManagerEntity) => {
      this.CURRENT_MANAGER = x;

      this.goTo(['/']);
    })
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      if (res != null) {
        this.CURRENT_IP = res.ip;
        this.http.get("https://ipapi.co/"+this.CURRENT_IP+"/json").subscribe((result: any) => {
          if (result != null) {
            console.log(result);
            this.CURRENT_LOCATION = result;

            moment.locale(this.CURRENT_LOCATION.languages.split(',')[0]);
          }
        });
      }
    });
  }

  headers() {
    let options = {
      headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    };
    return options;
  }

  checkUrl(urlToCheck: string) {
    if (this.router.url.indexOf(urlToCheck) >= 0) return true;
    return false;
  }

  checkHostInLocation(urlToCheck: string) {
    if (window.location.host.indexOf(urlToCheck) >= 0) return true;
    return false;
  }

  checkHostInReferrer(urlToCheck: string) {
    if(document.location.hostname.includes(urlToCheck)) {
      return true;
    }else {
      return false;
    }
  }


  getLastVariableFromUrl(name: string) {
    let toReturn;
    this.route.params.subscribe((params: Params) => {
      toReturn = params[name]
    });
    if (toReturn == null || toReturn == undefined) {
      let array = this.router.url.split('/');
      toReturn = array[(array.length - 1)];
    }
    return toReturn;
  }

  goBack() {
    this.location.back();
  }

  goTo(url) {
    this.router.navigate(url);
  }

  goExternalTo(url) {
    window.open(url, '_system')
  }

  goInternalTo(url) {
    this.router.navigateByUrl(url);
  }

  t(word: string){
    if(this.TRANSLATIONS != null && this.TRANSLATIONS.length > 0) {
      if(this.TRANSLATIONS.find(x => x.word == word) != null){
        return this.TRANSLATIONS.find(x => x.word == word).translation;
      }else{
        return word;
      }
    }else{
      return word;
    }
  }
}
