import {Component, ElementRef, ViewChild} from '@angular/core';
import {Shared} from "../../shared/shared";
import {UserService} from "../../../service/UserService";
// import Swiper JS
import Swiper, {Navigation, Pagination} from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {CalendarComponent} from "ionic2-calendar";
import {CalendarComponent as MyCalendarComponent} from "../generic/table/calendarComponent/calendarComponent";
import {CalendarMode, Step} from "ionic2-calendar/calendar";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {ExamService} from "../../../service/ExamService";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {ButtonComponent} from "../generic/table/buttonComponent/buttonComponent";
import {Angular5Csv} from "angular5-csv/dist/Angular5-csv";
import {DatePipe} from "@angular/common";
import {AlertController} from "@ionic/angular";
import {UserEntity} from "../../../model/UserEntity";

const swiper = new Swiper('.swiper', {
  // configure Swiper to use modules
  modules: [Navigation, Pagination],
});

@Component({
  selector: 'app-users',
  templateUrl: 'users.html',
  styleUrls: ['users.scss'],
  providers: [DatePipe]
})
export class Users {

  users: UserEntity[]
  current: UserEntity

  examCategory: string
  examCallYear: number

  currentTab = 1;
  currentSubsTab = 1;

  isCheckingEmail: number = 0;
  isCheckingNif: number = 0;

  rightDiv: number = 1;

  @ViewChild('myCalendar') myCalendar: CalendarComponent;

  language = "en_US";

  headers: any = {
    columns: {
      name: {title: 'Name', hide: false, filter: false},
      surname: {title: 'Surname', hide: false, filter: false},
      email: {title: 'Email', hide: false, filter: false},
      mobile: {title: 'Mobile', hide: false, filter: false},

      nif: {title: 'Nif', hide: true, filter: false},
      address: {title: 'Address', hide: true, filter: false},
      corpId: {title: 'Corp ID', hide: true, filter: false},
      country: {title: 'Country', hide: true, filter: false},
      cp: {title: 'Cp', hide: true, filter: false},
      id: {title: 'Id', hide: true, filter: false},
      password: {title: 'Password', hide: true, filter: false},
      privacy1: {title: 'Privacy1', hide: true, filter: false},
      privacy2: {title: 'Privacy2', hide: true, filter: false},
      profileImage: {title: 'ProfileImage', hide: true, filter: false},
      province: {title: 'Province', hide: true, filter: false},
      reference: {title: 'Reference', hide: true, filter: false},
      town: {title: 'Town', hide: true, filter: false}
    },
    actions: false,
  }

  @ViewChild('fileButton') fileButton: ElementRef;
  @ViewChild('fileButton3') fileButton3: ElementRef;

  constructor(private userService: UserService, private datePipe: DatePipe, private modalService: NgbModal, private examService: ExamService, private shared: Shared, private alertController: AlertController) {
  }

  ngOnInit() {
    this.myInit();
  }

  async myInit(){
    this.userService.getAllUsers().subscribe(users => {
        if (users != null) {
          this.users = users;
        }
      }
    )
  }

  async rowSelectedEmitter(row: UserEntity) {

    this.rightDiv = 1;
    this.current = row;
    this.current.password = "";
    this.currentSubsTab = 1;

    this.examService.getExamAssociated(row.examAsociated).subscribe(exam => {
      this.examCategory = exam.category
      this.examCallYear = exam.callYear
    })

  }

  saveUser() {

  }

  checkNull(data){
    if(data==null || data==undefined) {
      return "";
    }else{
      return data;
    }
  }

  openSearchUser() {
    this.rightDiv = 0;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}

