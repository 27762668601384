import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";

@Injectable()
export class LoginService extends ParentService{

  public login(email: string, password: string) {
    return this.http.post(this.shared.MAGNAMENT_URL+'/login?username='+email+'&password='+password, this.shared.headers());
  }

}
