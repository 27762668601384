import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {UserEntity} from "../model/UserEntity";

@Injectable()
export class UserService extends ParentService{

  public getAllUsers(){
    return this.http.get<UserEntity[]>(this.shared.MAGNAMENT_URL+'/getAllUsers', this.shared.headers())
  }

}
