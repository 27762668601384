import {Component, ViewEncapsulation} from '@angular/core';
import {Shared} from "../../shared/shared";
import {TranslationEntity} from "../../../model/TranslationEntity";
import {DropdownComponent} from "../generic/table/dropdownComponent/dropdownComponent";
import {TextComponent} from "../generic/table/textComponent/textComponent";
import {ExamService} from "../../../service/ExamService";
import {TranslationService} from "../../../service/TranslationService";
import {ExamEntity} from "../../../model/ExamEntity";

@Component({
  selector: 'app-translation',
  templateUrl: 'translation.html',
  styleUrls: ['translation.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Translation {

  translations: TranslationEntity[];

  exams: ExamEntity[];

  showTable = true;

  newEntity: TranslationEntity;
  modeAdd = false;

  headers: any = {
    columns: {
      id: {title: 'Statement', hide: true, filter: false},
      language: {title: 'Language', hide: false, filter: true, type: "html"},
      subLanguage: {title: 'Sublanguage', hide: false, filter: true, type: "html"},
      word: {title: 'Word', hide: false, filter: true, type: "html"},
      translation: {
        title: 'Translation', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => cell,
        renderComponent: TextComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeText.subscribe(data => {
              let translation = this.translations.find(x => x.id == data.row.id)
              translation.translation = data.text;
              this.saveTranslation(translation);
            });
          }
        },
      },
      examId: {
        title: 'Exam', hide: false, filter: true, editable: true, type: "custom",
        valuePrepareFunction: (cell, row) => { return {cell: cell, options: this.exams}},
        renderComponent: DropdownComponent,
        onComponentInitFunction: (instance) => {
          if (instance != null) {
            instance.changeDropdown.subscribe(data => {
              let translation = this.translations.find(x => x.id == data.row.id)
              translation.examId = data.id;
              this.saveTranslation(translation);
            });
          }
        },
      }
    },
    actions: false,
  }

  constructor(private translationService: TranslationService, private examService: ExamService, private shared: Shared) {
  }

  ngOnInit() {
    this.translationService.getTranslations().subscribe( x=> {
      if(x!=null){
        this.translations = x;
      }
    });


    this.examService.getExams().subscribe(x => {
      if (x != null) {
        this.exams = x;
      }
    });

  }

  async saveTranslation(translation: TranslationEntity) {
    this.translationService.saveTranslation(translation).subscribe( t =>{
        if(t!=null) {
          this.translations.push(t);
          this.translations = this.translations.filter(x => {return x});
        }
    });
  }

  async saveNewTranslation() {
    this.saveTranslation(this.newEntity);
    this.modeAdd = false;
  }

  newTranslation(){
    this.modeAdd = true;
    this.newEntity = new TranslationEntity();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
