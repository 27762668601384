import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {SubjectsRoutingModule} from "./subjects-routing.module";
import {Subjects} from "./subjects";
import {SubjectService} from "../../../service/SubjectService";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    SubjectsRoutingModule,
    NgxDatatableModule,
    TableModule,
    NgSelectModule,
    DlDateTimePickerModule,
  ],
  providers: [SubjectService],
  exports: [
    Subjects
  ],
  declarations: [Subjects]
})
export class SubjectsModule {}
