import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {SubjectEntity} from "../model/SubjectEntity";


@Injectable()
export class SubjectService extends ParentService{

  public getSubjects() {
    return this.http.get<SubjectEntity[]>(this.shared.MAGNAMENT_URL+'/getSubjects', this.shared.headers());
  }

  public saveSubject(subject) {
    return this.http.post<SubjectEntity>(this.shared.MAGNAMENT_URL+'/saveSubject', subject);
  }

}
