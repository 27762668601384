import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {TranslationRoutingModule} from "./translation-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TableModule} from "../generic/table/table.module";
import {Translation} from "./translation";
import {NgSelectModule} from "@ng-select/ng-select";
import {DlDateTimePickerModule} from "angular-bootstrap-datetimepicker";
import {TranslationService} from "../../../service/TranslationService";
import {ExamService} from "../../../service/ExamService";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TranslationRoutingModule,
    NgxDatatableModule,
    TableModule,
    NgSelectModule,
    DlDateTimePickerModule,
  ],
  providers: [ExamService, TranslationService],
  exports: [
    Translation
  ],
  declarations: [Translation]
})
export class TranslationModule {}
