import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Magnament } from './magnament';
import {MagnamentRoutingModule} from "./magnament-routing.module";
import {LoginService} from "../../service/LoginService";
import {ExamsModule} from "./exams/exams.module";
import {NgToggleModule} from "ng-toggle-button";
import {TranslationModule} from "./translation/translation.module";
import {SubjectsModule} from "./subjects/subjects.module";
import {RouterModule} from "@angular/router";
import {DndModule} from 'ngx-drag-drop';
import {UsersModule} from "./users/users.module";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    MagnamentRoutingModule,
    ExamsModule,
    NgToggleModule.forRoot(),
    TranslationModule,
    SubjectsModule,
    RouterModule,
    DndModule,
    UsersModule,
  ],
  providers: [LoginService],
  exports: [Magnament],
  declarations: [Magnament]
})
export class MagnamentModule {}

